import { useEffect, useState } from "react";
import "./header.scss";

const TwoColumnDropdown = ({ options, name, white }: any) => {

    const [show, setShow] = useState(false)

    return (

        <div className="lg:flex flex-col items-center" onMouseLeave={() => setShow(false)}>
            <div
                onClick={() => setShow(!show)}
                onMouseEnter={() => setShow(true)}
                data-dropdown-toggle="dropdownHover"
                data-dropdown-trigger="hover"
                className="flex lg:items-center flex-col">
                <button className={`${white ? "lg:text-black text-black" : "lg:text-white"} nav-list-item border-none justify-between focus:outline-none font-medium text-base px-5 py-2.5 text-center inline-flex items-center pointer-events-none`}
                    type="button">{name}
                    <svg
                        className={` w-2.5 h-2.5 ms-3 ${show ? "rotate-180" : "'"}`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                    </svg>
                </button>
                <svg className={`absolute justify-between bottom-1.5 ${show ? '' : 'hidden'}`}
                    width="20px"
                    height="20px"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#ffffff" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <polygon points="8,5 13,10 3,10"></polygon> </g></svg>
            </div >

            <div id="dropdownHover" style={{ top: "58px" }} className={`z-10 bg-white pr-4 pt-4 pl-4 pb-4 lg:flex ${name == "Destinations" ? "flex" : ""} lg:absolute w-full lg:w-auto rounded-lg shadow ${show ? '' : 'hidden lg:hidden'}`}
            >
                <ul className="lg:py-2 lg-px-2 text-gray-700" aria-labelledby="dropdownHoverButton">
                    {
                        options ? (options[0].map((item: any, index: number) => {
                            return (
                                <li key={index} className="pb-2 lg:pb-0">
                                    <a href={item.link} className="block px-1 text-sm py-1 lg:px-3 lg:py-3">{item.label}</a>
                                </li>
                            )
                        })) : ''
                    }
                </ul>
                <ul className={`lg:py-2 lg-px-2 text-gray-700  ${name == "Destinations" ? "pl-20 lg:pl-0" : ""}`} aria-labelledby="dropdownHoverButton">
                    {
                        options ? (options[1].map((item: any, index: number) => {
                            return (
                                <li key={index} className="pb-2 lg:pb-0">
                                    <a href={item.link} className="text-sm block px-1 py-1 lg:px-3 lg:py-3">{item.label}</a>
                                </li>
                            )
                        })) : ''
                    }
                </ul>
            </div>
        </div >
    )
}

export default TwoColumnDropdown;